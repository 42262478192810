import Table from "./components/Table";
import Table2 from "./components/Table2";
import Table3 from "./components/Table3";

import Form from "./components/Form";
import Form2 from "./components/Form2";
import Form3 from "./components/Form3";


import Sections from "./components/Sections";
import Sections2 from "./components/Sections2";
import Sections3 from "./components/Sections3";


import Home from "./components/Home";
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import { useState } from "react";

function App() {

  const [sec, setSec] = useState();
  const [sec2, setSec2] = useState();
  const [sec3, setSec3] = useState();


  return (
    <BrowserRouter >
    <Routes>
      <Route path='/' element={<Home/>} />

      <Route path='/one' element={<Sections3 sec={sec3} setSec={setSec3}/>} />

      <Route path='/second' element={<Sections2 sec={sec2} setSec={setSec2}/>} />
      <Route path='/third' element={<Sections sec={sec} setSec={setSec}/>} />
      <Route path={'/'+ sec} element={<Table sect={sec}/>} />
      <Route path={'/'+ sec2 + '2'} element={<Table2 sect={sec2}/>} />

      <Route path={'/'+ sec3 + '3'} element={<Table3 sect={sec3}/>} />

      <Route path='/form' element={<Form />} />
      <Route path='/form2' element={<Form2 />} />

      <Route path='/form3' element={<Form3 />} />


    </Routes>
     
    </BrowserRouter>
  );
}

export default App;
